import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const teamPic = {
  position: 'relative',
  top: '0',
  botom: '0',
  margin: 'auto'
}

const Team = () => {
  return (
      <Layout>
        <Seo title="Jill Zimmermann und Ihr Team" />
        <div className="content">
          <div className='title'><h1>Team</h1></div>
          <Container>
            <Row style={{marginBottom: "40px"}}>
            <Col style={teamPic}>
                <div>
                <StaticImage
                  src="../images/team/jill_zimmermann.jpg"
                  width={250}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Jill Zimmermann"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
              <Col md>
                <h5>Jill Zimmermann</h5>
                <h5>Spielgruppenleiterin</h5>
                <p>Seit 2013 bin ich ausgebildete Fachfrau Betreuung Kind und arbeite seither als Gruppenleiterin in einer wundervollen Kita. Seit sechs Jahren darf ich in dieser Kita wöchentliche Waldtage leiten. Das Begleiten von Kindern ist mir eine Herzensangelegenheit, so entschied ich mich dazu, eine eigene Spielgruppe zu eröffnen, in welcher der Fokus auf die Natur gerichtet ist. Ich bin selbst in Uster aufgewachsen und kenne die Umgebung und den nahe gelegenen Wald gut.</p>
              </Col>
              
            </Row>
            <Row style={{marginBottom: "40px"}}>
            <Col style={teamPic}>
                <div>
                <StaticImage
                  src="../images/team/brigit_strasser.jpg"
                  width={250}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Jill Zimmermann"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
              <Col md>
                <h5>Brigit Strasser</h5>
                <h5>Mitarbeiterin</h5>
                <p>Ich bin Mutter von zwei nun mittlerweile erwachsenen Kindern. Bei meiner Erziehung waren mir soziale wie naturverbundene Elemente sehr wichtig. Daher freue ich mich, Jill bei ihrer Naturspielgruppe zu unterstützen.</p>
              </Col>
              
            </Row>
            <Row style={{marginBottom: "40px"}}>
            <Col style={teamPic}>
                <div>
                <StaticImage
                  src="../images/team/sami.jpg"
                  width={250}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Jill Zimmermann"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
              <Col md>
                <h5>Samuel & Ariana Mächler</h5>
                <h5>Grundstückbewirtschafter</h5>
                <p>Meine Ehefrau und ich betreiben seit 2017 einen kleinen bescheidenen Garten für den Ausgleich zu der gefühlt immer schneller bewegenden Zeit. Als Strassentransportunternehmer brauchte ich zwingend einen passenden Ausgleich für die Arbeit, die nie schnell genug zu erledigen ist. Daher haben wir uns nicht nur einen Garten angelegt, sondern haben uns noch für die reine Freude und der Liebe zu Tieren Zwergziegen angeschafft, die uns mit ihrer speziellen und eigenen Art jeden Tag ein Lächeln ins Gesicht zaubern. Die Arbeiten im Garten, zu sehen wie etwas gedeiht und wie lange es gehen kann, bis man Ergebnisse sieht, hat uns etwas wichtiges gelehrt und zwar, dass die Zeit für alle gleich ist, nur kann man dies schnell mal vergessen. Daher entstand in meinem Kopf ein Anliegen, ich möchte diese einfache, jedoch wichtige Tatsache mit Beispielen aus dem Gemüseanbau weitergeben. Es muss nicht immer noch besser sein oder noch schneller gehen. Manchmal zeigt uns die Natur wie Perfektion funktioniert. Deswegen versuchen wir in unserem Garten mehr mit der Natur zu arbeiten und nicht gegen sie. Jedes Lebewesen hat seine Aufgabe oder kann eine wichtige Funktion erfüllen.</p>
              </Col>
              
            </Row>
          </Container>
        </div>
      </Layout>
  )
}

export default Team;